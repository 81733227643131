import React from "react"
import { Helmet } from "react-helmet"

import { StaticQuery, graphql } from "gatsby"

export const Seo = ({ description, keywords, title, image, url, author }) => {
  return (
    
    <StaticQuery
      query={detailsSeo}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = url || data.site.siteMetadata.url
        const metaImage = image || data.site.siteMetadata.image
        const metaKeywords = keywords || [
          "ニューヨーク",
          "DX",
          "ウェブサイト",
          "アプリ",
          "開発",
          "ブランディング",
          "デザイン",
        ]
        const metaAuthor = author || data.site.siteMetadata.author
        return (
          <Helmet 
            htmlAttributes={{
              lang: 'en',
            }}
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:author`,
                content: metaAuthor,
              }
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords,
                  }
                : []
            )}
          />
        )
      }}
    />
  )
}

const detailsSeo = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }
`

// import React from "react"
// import PropTypes from "prop-types"
// import { Helmet } from "react-helmet"
// import { useLocation } from "@reach/router"
// import { useStaticQuery, graphql } from "gatsby"
// export interface SEOProps {
//   description: any
//   lang: any
//   meta: any
//   title: any
//   image: any
//   article: any
// }

// const SEO: React.SFC<SEOProps> = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       site {
//         siteMetadata {
//           title
//           description
//           image
//         }
//       }
//     }
//   `)
//   return (
//     <Helmet>
//       <html lang="ja" />
//       <title>{data.site.siteMetadata.title}</title>
//       <meta name="description" content={data.site.siteMetadata.description} />
//       <meta name="image" content={data.site.siteMetadata.image} />
//     </Helmet>
//   )
// }

// export default SEO
