import React, { useState } from "react"
import { Link } from "gatsby"
import logo from "../images/SSD-logo.png"

export interface FooterComponentProps {}


declare var require: any

const FooterComponent: React.SFC<FooterComponentProps> = () => {
  const [menu, setMenu] = useState([
    { item: "News", link: "/articles/" },
    { item: "About ", link: "/company-business/" },
    { item: "Contact Us", link: "https://purekotony.typeform.com/to/TRatHf" },
  ])

  

  return (
    <footer className="container-full container-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <ul className="container-footer__menu">
              {menu.map((item, i) => (
                <li key={i}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.item}
                  </a>
                </li>
              ))}
            </ul>
            <img
              className="container-footer__logo"
              src={logo}
              alt="SUPER SONIC DESIGN INC."
            />
          </div>
          <div className="col-lg-6">
            <p>Contact Us</p>
            <ul className="container-footer__address">
              <li>
                <a
                  href="https://maps.google.com/?q=40.748902,-73.987014"
                  target="_blank"
                  rel="noreferrer"
                >
                  43 W33rd street, #202, New York, NY, 10001
                </a>
              </li>
              <li>
                <span>Phone:</span>
                <a href="tel: +1-646-894-0318" target="_blank" rel="noreferrer">
                  +1-646-894-0318
                </a>
              </li>
              <li>
                <span>Email:</span>
                <a
                  href="mailto:kage@supersonicdesign.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  kage@supersonicdesign.com
                </a>
              </li>
              <li>
                <span>Website:</span>
                <a href="/">supersonicdesign.com</a>
              </li>
            </ul>
            {/*
            <ul className="container-footer__social">
              <li>
                <Link
                  to="https://facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebookIcon} alt="Facebook" />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UCCsQEVZrY951F0KbgWf08uw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={youtubeIcon} alt="Youtube" />
                </Link>
              </li>
            </ul>
            */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
